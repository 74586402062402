<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>会员管理</el-breadcrumb-item>
      <el-breadcrumb-item>员工列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
  <el-form-item label="手机号">
    <el-input
        clearable
        v-model="pageParms.keyword"
        placeholder="请输入手机号"
        size="mini"
      ></el-input>
  </el-form-item>
  <el-form-item >
    <el-button size="mini" type="primary" @click="employee"
        >查询</el-button
  ></el-form-item>
  <el-form-item >
    <el-button size="mini" type="primary" @click="outerVisibler = true"
        >新增</el-button
  ></el-form-item>
  </el-form>
      
      
      
      <el-table :data="employeeList" style="width: 100%">
        <el-table-column prop="id" width="200" label="ID"> </el-table-column>
        <el-table-column prop="name" label="姓名" width="200">
        </el-table-column>
        <el-table-column prop="mobile" label="手机号" width="200">
        </el-table-column>
        <el-table-column prop="jinrong" label="金融权限" width="200">
          <template slot-scope="scope">
            <div>
              {{ authorityList.get(scope.row.jinrong) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="fudai" label="直播福袋权限 " width="200">
          <template slot-scope="scope">
            <div>
              {{ authorityList.get(scope.row.fudai) }}
              <!-- scope.row.fudai === 1 ? "有权限" : "没有权限"  -->
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="idCard" label="身份证号" width="200">
        </el-table-column> -->
        <el-table-column label="操作" min-width="150" fixed="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="editEmployee(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="text"
              @click="updateAuthorityModal(scope.row)"
              >更新权限</el-button
            >
            <el-button
              size="mini"
              @click="delEmployee(scope.row.id)"
              type="text"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page="pageParms.page"
        :page-sizes="pageParms.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <el-dialog
      title="新增员工"
      :visible.sync="outerVisibler"
      :append-to-body="true"
      width="580px"
    >
      <el-form
        label-width="75px"
        label-position="left"
        :rules="rules"
        ref="ruleForm"
        :model="ruleForm"
        style="margin: 0 30px"
      >
        <el-form-item label="姓名:" prop="name">
          <el-input
            v-model="ruleForm.name"
            clearable
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>

        <el-form-item label="手机号:" prop="mobile">
          <el-input
            clearable
            v-model="ruleForm.mobile"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item label="身份证号:" prop="idCard">
          <el-input
            clearable
            v-model="ruleForm.idCard"
            placeholder="请输入身份证号"
          ></el-input>
        </el-form-item> -->

        <el-form-item style="padding-left: 70px">
          <el-button type="primary" @click="addEmployee">确认</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="编辑员工"
      :visible.sync="editvisible"
      :append-to-body="true"
      width="580px"
    >
      <el-form
        label-width="75px"
        label-position="left"
        :model="EmployeeByIdList"
        style="margin: 0 30px"
      >
        <el-form-item label="姓名:" required>
          <el-input
            v-model="EmployeeByIdList.name"
            clearable
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>

        <el-form-item label="手机号:" required>
          <el-input
            clearable
            v-model="EmployeeByIdList.mobile"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item label="身份证号:" required>
          <el-input
            clearable
            v-model="EmployeeByIdList.idCard"
            required
            placeholder="请输入身份证号"
          ></el-input>
        </el-form-item> -->

        <el-form-item style="padding-left: 70px">
          <el-button type="primary" @click="confirm">确认</el-button>
          <el-button @click="editvisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="更新权限"
      :visible.sync="updateAuthorityVisible"
      :append-to-body="true"
      width="580px"
    >
      <el-form
        label-width="120px"
        label-position="right"
        :model="EmployeeByIdList"
        style="margin: 0 30px"
      >
        <el-form-item label="金融权限:" required>
          <el-switch
            v-model="financeAuthority"
            active-text="有权"
            inactive-text="无权"
            @change="updateAuthority('1')"
          >
          </el-switch>
        </el-form-item>

        <el-form-item label="直播福袋权限:" required>
          <el-switch
            v-model="blessingBagAuthority"
            active-text="有权"
            inactive-text="无权"
            @change="updateAuthority('0')"
          >
          </el-switch>
        </el-form-item>

        <el-form-item style="padding-left: 70px">
          <el-button type="primary" @click="updateAuthorityVisible = false"
            >确认</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  // searchInternalEmployeeList,
  // searchInternalEmployeeById,
  // updateInternalEmployee,
  // insertInternalEmployee,
  // delInternalEmployee,
  searchFinancialUserList,
  searchFinancialUserById,
  updateFinancialUser,
  delFinancialUser,
  insertFinancialUser,
  updateFinancialUserPermit,
} from "../../api/index";
export default {
  data() {
    return {
      cacheId: 0,
      financeAuthority: false,
      blessingBagAuthority: false,
      updateAuthorityVisible: false,
      authorityList: new Map([
        [0, "无权"],
        [1, "有权"],
      ]),
      //  [{key:0,value:"没有权限"},{key:1,value:"有权限"}],
      id: 0,
      pageParms: {
        page: 1,
        pageSize: 10,
        keyword:undefined,
      },
      total: 0,
      employeeList: [],
      outerVisibler: false,
      editvisible: false,
      EmployeeByIdList: {},
      ruleForm: {
        id: "",
        idCard: "",
        mobile: "",
        name: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/,
            message: "手机号格式有误",
            trigger: "blur",
          },
        ],
        // idCard: [
        //   { required: true, message: "请输入身份证号", trigger: "blur" },
        // ],
      },
    };
  },
  created() {
    this.employee();
  },
  methods: {
    updateAuthorityModal(item) {
      this.cacheId = item.id;
      this.financeAuthority = item.jinrong === 1 ? true : false;

      this.blessingBagAuthority = item.fudai === 1 ? true : false;
      this.updateAuthorityVisible = true;
    },
    async updateAuthority(type) {
      const financeValue = this.financeAuthority ? 1 : 0;
      const blessingBagValue = this.blessingBagAuthority ? 1 : 0;
      const permit = type === "1" ? financeValue : blessingBagValue;
      const formData = new FormData();
      formData.append("id", this.cacheId);
      formData.append("permit", permit);
      formData.append("type", type);
       await updateFinancialUserPermit(formData);
      this.$message.success("更新权限成功");
      this.employee();

    },
    handleCurrentChange(data) {
      console.log(data);
      this.pageParms.page = data;
      this.employee();
    },
    handleSizeChange(data) {
      console.log(data);
      this.pageParms.pageSize = data;
      this.employee();
    },
    async employee() {
      // const res = await searchInternalEmployeeList(this.pageParms);
      const res = await searchFinancialUserList(this.pageParms);
      this.total = res.data.body.total;
      console.log(res);
      this.employeeList = res.data.body.list;
    },
    cancel() {
      this.outerVisibler = false;
      this.$refs.ruleForm.resetFields();
    },
    async editEmployee(id) {
      this.editvisible = true;
      // const res = await searchInternalEmployeeById({ id: id });
      const res = await searchFinancialUserById({ id: id });

      this.EmployeeByIdList = res.data.body;
      console.log(this.EmployeeByIdList);
    },
    async addEmployee() {
      if (!this.ruleForm.name) return this.$message.error("未填写姓名");
      if (!this.ruleForm.mobile) return this.$message.error("未填写手机号");
      // if (!this.ruleForm.idCard) return this.$message.error("未填写身份证号");
      // const res = await insertInternalEmployee({
      const res = await insertFinancialUser({
        id: this.ruleForm.id,
        idCard: this.ruleForm.idCard,
        mobile: this.ruleForm.mobile,
        name: this.ruleForm.name,
      });

      this.outerVisibler = false;
      this.$refs.ruleForm.resetFields();
      this.$message.success("添加员工成功");
      this.employee();
    },
    async delEmployee(id) {
      const formData = new FormData();
      formData.append("ids", id);
      // const res = await delInternalEmployee(formData);
      const res = await delFinancialUser(formData);
      this.employee();
    },
    async confirm() {
      // const res = await updateInternalEmployee({
      const res = await updateFinancialUser({
        id: this.EmployeeByIdList.id,
        idCard: this.EmployeeByIdList.idCard,
        mobile: this.EmployeeByIdList.mobile,
        name: this.EmployeeByIdList.name,
      });
      if (!this.EmployeeByIdList.name) return this.$message.error("未填写姓名");
      if (!this.EmployeeByIdList.mobile)
        return this.$message.error("未填写手机号");
      // if (!this.EmployeeByIdList.idCard)
      //   return this.$message.error("未填写身份证号");
      if (res.data.code !== 200) {
        return this.$message.error("修改员工信息失败");
      } else {
        this.$message.success("修改员工信息成功");
      }
      this.editvisible = false;
      this.employee();
    },
  },
};
</script>
<style>
.mr15 {
  margin-right: 15px;
}
</style>
